@use "../abstracts" as a;

.card-splide {
    @include a.flex(flex, column, center, center);
    width: 100%;
    max-width: 359px;
    min-height: 292px;
    box-shadow: 3px 2px 12px rgba(0, 0, 0, 0.18);
    padding: 16px;
    margin: auto;
    border-radius: 11px;

    &__icon {
        @include a.flex(flex, column, center, center);
        min-width: 91px;
        min-height: 91px;
        text-align: center;
        border: 3px solid a.color(primary);
        padding: 8px;
        margin-bottom: 1.5rem;
        border-radius: 50%;

        i {
            font-size: 2.813rem;
            color: a.color(primary);
        }

        &--image {
            min-width: 146px;
            min-height: 146px;
        }
    }

    &__image {
        width: 100%;
        height: 200px;
        object-fit: cover;
    }

    &__title {
        color: a.color(grayScale, 700);
        font-size: a.size(text, 600);
        font-weight: 900;
        margin-bottom: 1rem;
        text-align: center;
    }

    &__subtitle {
        font-size: a.size(text);
        color: a.color(primary);
        text-align: center;
    }

    &__text {
        text-align: center;
    }

    &__link {
        color: a.color(primary);
    }
}
