@use "../abstracts" as *;
@use "../components/card";
@use "../components/splide";

.button {
    width: max-content;
    border-radius: 6px;
    border: 0 !important;
    background-color: #56acbb;
}

.title {
    color: color(primary, gray-2);
    font-weight: 700;
    font-size: 3rem;
    font-family: "Roboto", sans-serif;

    &::after {
        content: "";
        display: block;
        width: 5%;
        height: 3px;
        margin: 1.2rem auto;
        background-color: color(primary, red-1);
        border-radius: 8px;
    }
}

.banner {
    @include for-desktop-up {
        margin-bottom: 20px;
    }

    @include for-phone-only {
        margin-bottom: 20px;
    }

    @include for-phone-only {
        // .splide__slide {
        //     height: unset !important;

        //     img {
        //         width: 100%;
        //         height: 190px;
        //         object-fit: cover;
        //     }
        // }
    }

    &-splide {
        
        &__item {
            @include flex(flex, column, center, center);
        }
        .container {
            position: absolute;
            bottom: 40%;
            left: 10%;
            width: max-content;

            @include for-tablet-portrait-only {
                bottom: 45%;
                left: 10%;
            }
            @include for-phone-only {
              bottom: 0;
              right: 10%;
              left: unset;
            }

            // @include for-phone-only {
            //     position: static;
            //     width: unset;
            //     margin-top: 24px;
            // }
        }

        &__description {
            
            font-family: "Roboto", sans-serif;

            color: color(primary, gray-4);
            h1, h2 {
                font-weight: 900;
                font-size: 32px;

                &::after {
                    content: "";
                    display: block;
                    width: 125px;
                    height: 3px;
                    margin: 16px 0;
                    background-color: color(primary, red-1);
                }

                @include for-tablet-portrait-only {
                    font-size: 24px;
                }

                @include for-phone-only {
                    font-size: 20px;
                }
            }


            @include for-phone-only {
                h1 {
                    text-align: center;

                    &::after {
                        margin: 1.2rem auto 24px;
                    }
                }
            }

            p {
                font-size: 16px;
                max-width: 65ch;
                color: color(primary, gray-1);

                @include for-tablet-portrait-only {
                    font-size: 14px;
                    max-width: 40ch;
                }

                @include for-phone-only {
                  font-size: size(paragraph, 100); 
                  max-width: 34ch;
                }
            }
            
            a {
                padding: 12px 16px;
                color: #fff;
                border: 1px solid color(primary, red-1) !important;
                border-radius: 50px;
                text-transform: uppercase;
                font-size: 14px;
                font-weight: 700;
                display: inline-block;
                margin-top: 24px;
                transition: all 0.7s;
                background-color: color(primary, red-1);

                &:hover {
                    background-color: #fff !important;
                    color: color(primary, red-1) !important;
                    border: 1px solid color(primary, red-1) !important;
                }

                @include for-phone-only {
                    display: block;
                    // margin: 24px auto;
                    width: max-content;
                    font-size: 10px
                }
            }
        }
    }

    .splide {
      &__arrow {
        &--prev {
          left: 10em;
        }
        &--next {
          right: 10em;
        }
      }
    }    

    @include for-tablet-portrait-only {
        .splide__arrow {
            top: 60%;
        }
    }
}

.about {
    color: color(primary, gray-3);
    margin-bottom: 54px;

    img {
        border-radius: 20px;
    }

    &-title {
        font-size: 28px;
        font-weight: 700;
        color: color(primary, gray-2);

        &::after {
            content: "";
            display: block;
            width: 50px;
            height: 3px;
            margin: 1.2rem 0;
            background-color: color(primary, red-1);
        }
    }

    @include for-phone-and-tablet {
        &-title {
            text-align: center;
            margin-top: 16px;

            &::after {
                margin: 1.2rem auto 24px;
            }
        }
    }

    @include for-phone-only {
        margin-top: 64px;
    }

    &-description {
        .headline {
            font-size: 28px;
            font-weight: 700;
            margin-bottom: 12px;

            @include for-phone-and-tablet {
                text-align: center;
            }
        }

        .description {
            font-size: 16px;
            line-height: 24px;
            color: color(primary, gray-1);
            margin-bottom: 10px;

            @include for-phone-only {
              font-size: size(paragraph, 200);
            }
        }

        .link {
            padding: 12px 64px;
            color: color(primary, red-1);
            font-family: "Roboto", sans-serif;
            border: 1px solid color(primary, red-1);
            border-radius: 50px;
            font-size: 14px;
            font-weight: 700;
            display: inline-block;
            margin-top: 24px;
            transition: all 0.7s;

            &:hover {
                background-color: color(primary, red-1);
                color: #fff;
            }

            @include for-phone-and-tablet {
                display: block;
                margin: 24px auto;
                width: max-content;
            }
        }
    }
}

.courses {
    @include for-desktop-up {
        margin-bottom: 80px;
    }

    .course__image {
        border-radius: 20px;
    }

    &-card {
        max-width: 240px;
        min-height: 159px;
        box-shadow: 6px 6px 15px rgba(0, 0, 0, 0.12);
        background-color: color(primary, blue-3);

        padding: 16px;
        margin: auto;
        border-radius: 11px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 16px;
        transition: all 1s;

        &__figure {
            background-color: unset;
            border-radius: 20px;

            img {
                width: 40px;
                object-fit: cover;
            }
        }

        &__title {
            font-size: 24px;
            font-weight: 900;
            line-height: 28px;
            text-align: center;
            margin-bottom: 0;
        }

        &:hover {
            background-color: #fff;
            .courses {
                &-card {
                    &__figure {
                        img {
                            fill: #fff;
                        }
                    }

                    &__title {
                        color: color(primary, blue-3);
                    }
                }
            }
        }
    }
}

.structures {
    background-color: #f8f8f8;

    .title {
        margin-bottom: 48px;
    }

    &-button {
        color: color(primary, red-1);
        display: block;
        text-align: center;
        margin: 32px auto 0;
        font: 700 16px/20px "Roboto", sans-serif;
        text-transform: uppercase;
        border: 1px solid color(primary, red-1);
        border-radius: 50px;
        padding: 16px 24px;
        width: max-content;
        transition: all 0.7s;

        &:hover {
            background-color: color(primary, red-1);
            color: #fff;
        }

        @include for-phone-and-tablet {
            margin-top: 64px;
        }
    }

    .splide__list {
        // gap: 32px;

        figure {
            flex-direction: column;
            gap: 16px;
            max-width: 250px;

            @include for-phone-and-tablet {
                max-width: unset;
            }

            img {
                border-radius: 20px;
                width: 274px;
                height: 202px;
                object-fit: cover;
            }

            h4 {
                font: 700 20px/29px "Roboto", sans-serif;
                color: color(primary, gray-2);
            }
        }
    }
}

.benefits {
    margin-top: 40px;
    .cards-list {
        margin-top: 48px;
        display: grid;
        grid-template-columns: repeat(3, 1fr);
        gap: 35px;

        .card-item {
            display: flex;
            gap: 24px;

            figure {
                color: color(primary, red-1);
                font-size: 32px;
            }
            .card-text {
                margin-top: 10px;
                h4 {
                    color: #707175;
                    font-weight: 700;
                    font-size: 22px;
                    line-height: 26px;

                    @include for-phone-and-tablet {
                        text-align: center;
                    }
                }

                p {
                    color: #8b919e;
                    font-size: 14px;
                    margin-top: 16px;

                    @include for-phone-only {
                      font-size: size(paragraph, 200);
                    }
                }
            }

            @include for-phone-and-tablet {
                &:first-of-type .card-text h4 {
                    min-height: 52px;
                }
            }

            @include for-tablet-portrait-only {
                .card-text {
                    h4 {
                        font-size: 16px;
                    }
                }
            }

            @include for-phone-and-tablet {
                .card-text {
                    margin-top: 0;

                    h4 {
                        font-size: 16px;
                    }
                }
            }
        }

        @include for-phone-and-tablet {
            .card-item {
                gap: 0;
                flex-direction: column;
                align-items: center;

                figure {
                    font-size: 36px;
                    margin: 0;
                }
            }
        }
    }

    @include for-phone-and-tablet {
        .cards-list {
            grid-template-columns: 1fr 1fr;
            gap: 40px;
        }
    }
}

.blog {
    background-color: #f8f8f8;

    .container {
        padding: 16px 32px;
    }
    .title {
        margin-bottom: 48px;
    }

    &-card {
      @include grid(grid, auto, 204px 108px, 1rem);
      border-bottom: 2px solid color(primary, red-1);
        padding: 0 20px;

        &__figure {
            img {
                border-radius: 20px;
            }
        }

        &__header {
            font-family: "Roboto", sans-serif;
            padding-bottom: 24px;
            margin-bottom: 16px;

            h4 {
                font-size: 22px;
                font-weight: 700;
                line-height: 32px;
                text-align: center;
                color: color(primary, gray-2);
                margin: 0 auto 16px;
            }

            p {
                font-size: 14px;
                font-weight: 400;
                line-height: 20px;
                color: color(primary, gray-1);
                text-align: justify;

                @include for-phone-only {
                  font-size: size(paragraph, 200);
                }
            }
        }

        &__footer {
            p {
                font-size: 14px;
                text-align: center;
                color: color(primary, gray-1);
            }
        }
    }
}

.covenants {
    margin-bottom: 56px;

    &__image {
      filter: grayscale(100%);
      &:hover {
        filter: grayScale(0);
      }
    }
    
    @include for-phone-only {
        margin-top: 64px;
    }

    &-button {
        color: color(primary, gray-1);
        display: block;
        text-align: center;
        margin: 0 auto 40px;
        font: 400 16px/20px "Roboto", sans-serif;
    }
}
