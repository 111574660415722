@use "../abstracts" as *;

@import "~@splidejs/splide/dist/css/splide.min.css";

// Splide Overwrite
.splide {
    &__arrow {
        background: transparent;
        border: 2px solid #c9c9c9;

        &--next {
            right: -8em;
            width: 54px;
            height: 54px;
        }

        &--prev {
            left: -8em;
            width: 54px;
            height: 54px;
        }

        @include for-phone-and-tablet {
            &--next {
                right: 10em;
                width: 24px;
                height: 24px;
            }

            &--prev {
                left: 10em;
                width: 24px;
                height: 24px;
            }

            svg {
                fill: #a7a7a7;
                width: 16px;
                height: 24px;
            }
        }

        svg {
            fill: #a7a7a7;
            width: 28px;
            height: 46px;
        }

        @include for-desktop-up {
            &--next {
                right: -8em;
                width: 54px;
                height: 54px;
            }

            &--prev {
                left: -8em;
                width: 54px;
                height: 54px;
            }
        }
    }

    &__pagination {
        bottom: -4.5em;

        &__page {
            width: 10px;
            height: 10px;
            background: color(primary, gray-3);
        }

        &__page.is-active {
            background: color(primary, blue-3);
        }
    }
}
